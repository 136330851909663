<template>
  <div>
    <q-btn
      :color="normalItems.count > 0 ? 'teal' : 'danger'"
      text-color="white"
      :label="$t('On hand') + ': ' + normalItems.count"
      size="sm"
      class="full-width q-mb-sm"
      no-caps
      unelevated
      @click="handleClick"
    >
      <q-tooltip>
        <div class="mb-2 f-s-14">
          {{ $t('Warehouses') }}
        </div>

        <div
          v-for="warehouse in normalItems.warehouses"
          :key="warehouse"
          class="mb-1"
        >
          {{ warehouse }}
        </div>
      </q-tooltip>
    </q-btn>

    <q-slide-transition>
      <table
        v-if="isVisible"
        class="table table-valign-middle rounded text-center"
        :class="$q.dark.isActive ? 'text-white' : ''"
      >
        <thead>
          <tr>
            <th>{{ $t('Status') }}</th>

            <th>{{ $t('Amount') }}</th>

            <th>{{ $t('Warehouse') }}</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="item in filteredItems"
            :key="item.id"
          >
            <td>
              <label :class="`q-py-xs q-px-sm rounded bg-${itemStates[item.state]}`">
                {{ $t(stateName[item.state] || item.state) }}
              </label>
            </td>

            <td>{{ item.count }}</td>

            <td>{{ item.warehouse }}</td>
          </tr>
        </tbody>
      </table>
    </q-slide-transition>
  </div>
</template>

<script>
export default {
  name: 'ItemsTable',
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },
    offer: {
      type: Object,
      default () {
        return null
      }
    },
    showDetails: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isVisible: false,
      stateName: {
        normal: 'On hand',
        blocked: 'Blocked',
        booked: 'Booked',
        new: 'Received'
      },
      itemStates: {
        new: 'grey-5',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green',
        normal: 'success',
        expected: 'green',
        missing: 'grey'
      }
    }
  },
  computed: {
    filteredItems () {
      if (!this.items) {
        return []
      }

      return this.items.filter(x => x)
    },
    normalItems () {
      return this.items.reduce((acc, item) => {
        if (!item) {
          return acc
        }

        if (item.state === 'normal') {
          acc.count += Number(item.count)

          if (!acc.warehouses.includes(item.warehouse)) {
            acc.warehouses.push(item.warehouse)
          }
        }

        return acc
      }, { count: 0, warehouses: [] })
    },
    arrow () {
      return this.isVisible
        ? 'up'
        : 'down'
    }
  },
  methods: {
    handleClick () {
      this.isVisible = !this.isVisible
    }
  }
}
</script>
