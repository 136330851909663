<template>
  <div v-if="originalValue === value" :class="textClasses">
    {{ value }}
  </div>

  <div
    v-else
    class="row items-center"
    :class="textClasses"
  >
    <div class="q-pa-sm rounded bg-warning q-mr-sm">
      {{ originalValue }}
    </div>

    <div v-if="isNumber && diff !== 0" class="row items-center q-mr-sm">
      <div v-if="diff > 0">
        +
      </div>

      <div v-else>
        -
      </div>

      <div class="q-pa-sm rounded bg-grey-8 q-ml-sm">
        {{ Math.abs(diff) }}
      </div>
    </div>

    <div>
      ->
    </div>

    <div class="q-pa-sm q-ml-sm rounded bg-green-8">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextChangeDetection',
  emits: ['change'],
  props: {
    value: {
      type: [String, Number],
      default () {
        return ''
      }
    },
    isNumber: {
      type: Boolean,
      default () {
        return false
      }
    },
    textClasses: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      originalValue: '',
      diff: 0
    }
  },
  watch: {
    value (newVal) {
      if (this.isNumber) {
        this.diff = newVal - this.originalValue
        this.$emit('change', newVal)
      }
    }
  },
  mounted () {
    this.originalValue = this.value
  }
}
</script>
